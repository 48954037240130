import React from 'react';
import { Heading } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionPublicData = props => {
  const { publicData = {}, listingConfig, intl } = props;

  return (
    <section className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </Heading>
      <ul className={css.details}>
        {listingConfig.listingFields.map((config) => {
          const { key } = config;
          // this is rendered in the video gallery
          if (key == 'youtube_video_id') return;

          const value = publicData[key];
          if (value == null) return;
          return (
            <li key={key} className={css.detailsRow}>
              <span className={css.detailLabel}>{config?.showConfig?.label}</span>
              <span>{value}</span>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default SectionPublicData;
