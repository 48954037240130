import React from 'react';
import { Heading } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import classNames from 'classnames';
import css from './ListingPage.module.css';

const SectionMetaMaybe = props => {
  const { metadata = {}, intl } = props;
  const meta = Object.entries(metadata);
  if (meta.length === 0) return null;

  return (
    <section className={classNames(css.sectionDetails, css.sectionMeta)}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.analyticsTitle" />
      </Heading>
      <ul className={css.details}>
        {meta.map(data => {
          const [name, value] = data;
          return (
            <li key={name} className={css.metaRow}>
              <span className={css.detailLabel}>{name}</span>
              <span>{value}</span>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default SectionMetaMaybe;
